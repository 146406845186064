
/*General styling*/
.top {
  padding: 2%;
  text-align: center;
  color: white;
  border: solid 2px;
  border-color: #C1FEF3;
  margin: 2%;
  font-size: larger;
  font-weight: bold;
}

main {
  margin: 2%;
  border: solid 2px;
  border-color: #C1FEF3;
  padding: 2%;
  color: #C1FEF3;
}

a {
  color: white;
}

body {
  background-color: #002929;
  font-family: 'Courier New', Courier, monospace;
}


/*Styling for the buoy data page*/
.buoyData {
  text-align: center;
}

.buoyData table {
  margin: auto; 
  width: 30%;
  text-align: left;
}

.buoyInput input {
  margin-left: 10px;
  margin-right: 10px
}

.buoyHeader {
  text-align: center;
  padding-bottom: 20px;
  font-weight: bold;
}

.nearbyTable {
  overflow-y:scroll;
  height:150px;
  display:block;
}

.surfSpot{
  width: 100%;
  max-width: 500px;
  min-width: 200px;
}

#map{
  width: 100%;
  max-width: 500px;
  min-width: 200px;
}

.surfSpotTable {
  border: solid 2px;
  border-color: #C1FEF3;
  width: 500px;
  height: 20px;
  margin-bottom: 10px;
  overflow-x: auto;
}

.surfEditTable {
  border: solid 2px;
  border-color: #C1FEF3;
  max-width: 500px;
  min-width: 200px;
  width: 500px;             
  height: 20px;
  margin-bottom: 10px;
}

#editName {
  width: 100px;
}

.homepage {
  width: 100%;
}
.surfEditTable input {
  width: 50px;
}

.surfExpand {
  text-align: right;
}

#map { 
  height:400px;
  width: 500px;
  margin-bottom: 10px;
 }

.surfStuff {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
 }

 .Menu {
  margin-bottom: 20px;
 }
 .nav-link {
    background: #005353;
    color: #C1FEF3;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 2px;
    border: 2px solid white;
}

.nav-link:hover {
  color: black;
  background-color: white;
  font-weight: bold;
  border-color: black;
}

.createBuoys {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.nearbyTable {
  border: solid 2px;
  border-color: #C1FEF3;
}


/* Mobile Formatting */


@media (max-width: 600px) {
  .surfSpotTable {
    width: 100%;
  }

  .surfSpotTable th,
  .surfSpotTable td {
    display: block;
    position: relative;
  }

  .surfSpotTable tr {
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .surfEditTable th,
  .surfEditTable td {
    display: block;
    position: relative;
  }

  #spotTableHead{
    display: flex;
  }  

  .surfEditTable {
    width: 100%;
  }
  
  .nearbyTable tr{
    display: block;
    font-size: small;
  }

  #nearbyButton{
    font-size: small;
    width: 100px;
  }

  #map{
    width: 100%;
  }

}
